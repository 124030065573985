import React, {useEffect} from 'react';
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';


const Icons = (props) => {
    const {t} = useTranslation();

    return(
    <section id="icons" className="section icons">
        <div className="container">
            <header className="_heading major">
                <h3 className="titleone">{t('Zašto HHAUTO?')}</h3>
                <p className="content">{t('Naša ponuda i naši klijenti najbolje govore o nama.')}</p>

            </header>
            <div className="columns is-multiline is-variable is-8">
                <div className="column is-4">
                <i data-sal="zoom-in" data-sal-duration="1500" data-sal-delay="150" data-sal-easing="ease" className="fa fa-handshake-o fa-5x" aria-hidden="true"></i>
                <p 
                data-sal="slide-up"
                data-sal-duration="2000" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay="300" // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease"
                className="IconTitle">{t('Sigurna kupovina')}</p>

                </div>
                <div className="column is-4">
                <i data-sal="zoom-in" data-sal-duration="1500" data-sal-delay="150" data-sal-easing="ease" className="fa fa-car fa-5x" aria-hidden="true"></i>
                <p data-sal="slide-up"
                data-sal-duration="2000" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay="300" // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease" className="IconTitle">{t('Veliki izbor različitih vozila')} </p>

                </div>
                <div className="column is-4">
                <i data-sal="zoom-in" data-sal-duration="1500" data-sal-delay="150" data-sal-easing="ease" className="fa fa-check fa-5x" aria-hidden="true"></i>
                <p data-sal="slide-up"
                data-sal-duration="2000" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay="300" // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease" className="IconTitle"> {t('Pouzdanost i kvalitet')}</p>

                </div>
            </div>
        </div>
        
        </section>
        
        
        
        )
    }

        export default Icons