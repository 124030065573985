import React from 'react';
import CarImg from '../assets/images/car.svg';
import {useTranslation} from 'gatsby-plugin-react-i18next';


const Rent = (props) => {
    const {t} = useTranslation();

    return(
    <section id="rent" className="section rent">
        <div className="container">
            <div className="columns is-multiline is-variable is-8">
                
                <div className="column is-6">
                    <div className="inner">
                        <header className="major">
                        <h3>Rent-a-car</h3>

                        </header>
                            <p className="content">{t('U našoj ponudi imamo i rent-a-car uslugu. Moguće je rezervisati željeno vozilo u vremenskom periodu koji je Vama potreban, te se na osnovu njega definiše cijena.')}</p>
                            <p className="content">{t('Za više informacija kontaktirajte nas putem kontakt forme na našoj stranici ili pozovite naš kontakt telefon.')}</p>
                            <a href="#contact" className="button special">{t('Kontaktirajte nas')}</a>

                    </div>
                </div>
                <div className="column is-6 with_image">
                    
                </div>
                
            </div>
        </div>
    </section>
)
}

export default Rent
