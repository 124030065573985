import React from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Banner = (props) => {
    const {t} = useTranslation();
return(
    <section id="banner" className="section hero is-fullheight">
        <div className="inner">
            <header className="major">
                <h1>HH Auto Travnik</h1>
            </header>
            <div className="content">
                <p> {t('Uvoz i prodaja rabljenih vozila')} <br/>
                {t('Iznajmljivanje vozila')}</p>
            
                
            </div>
            <a href="#icons" className="button next scrolly">{t('Više')}</a>
        </div>
        <div 
       data-sal="slide-left"
       data-sal-delay="300"
       data-sal-easing="ease"
        className="ordiv">
            <div className="columns is-multiline is-variable is-8-desktop is-4-touch is-mobile">
                <div className="column is-6">
                    <a href="#cars">
                        {t('Interesuje Vas kupovina?')}
                    </a>
                </div>
                <div className="column is-6">
                    <a href="#rent" style={{opacity:0}}>
                    {t('Ili možda rent-a-car?')}
                    </a>
                </div>

            </div>
        </div>
        <div 
        data-sal="slide-left"
        data-sal-delay="300"
        data-sal-easing="ease"
        className="ordiv2">
            <div className="columns is-multiline is-variable is-8-desktop is-4-touch is-mobile">
                
                <div className="column">
                    <a href="#rent">
                    {t('Ili možda rent-a-car?')}
                    </a>
                </div>

            </div>
        </div>
    </section>
)
}
export default Banner
