import React, { useState, useEffect, Component }  from 'react'
import SliderHH from './SliderHH';
import axios from "axios";
import { graphql } from 'gatsby';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {withTranslation} from 'react-i18next';
import OlxImg from '../assets/images/olx.png';

class Cars extends Component {

    constructor(props){
        super(props);
        this.state={
            artikli:[],           

        };
    }

    componentDidMount(){
        axios.get('/.netlify/functions/artikli').then((res)=>{
           // const artikli = res.data.response.artikli;
            // this.setState({artikli:artikli}, function() {
            // });

        })

    }
    render(){
        const { t } = this.props;

    return(
    <section id="cars" className="section cars cars1">
        <div className="container">
            <header className="major">
                <h3>{t('Ponuda')}</h3>

            </header>
        </div>
        {/* <div className="container is-fluid">
            <div className="columns is-multiline is-mobile">
            {this.state.artikli.filter(i => i.kategorija_id==18).map(item =>  {
             
              return (
              <>
                <div className="column is-auto-desktop is-10-touch">
                   <div className="card">
                       <div className="card-image">
                           <figure className="image ">
                               <Carousel>
                                  
                                    <div>
                                        <img src={item.slike[0].default} />
                                    </div>
                                    <div>
                                        <img src={item.slike[1].default} />
                                    </div>
                                    <div>
                                        <img src={item.slike[2].default} />
                                    </div>
                                    <div>
                                        <img src={item.slike[3].default} />
                                    </div>
                                </Carousel>
                           </figure>
                       </div>
                       <div className="card-content">
                           <p className="title is-4">{item.naslov}</p>
                           
                           <p className="price">{item.cijena == 0 ? t('Po dogovoru') : item.cijena+" KM"}</p>
                           <a href={item.url} rel="noreferrer"  className="button special" target="_blank">{t('Više')}</a>
                       </div>
                   </div>
               </div>
                
                   
                   
               
               </>
              )})}
        </div>
            
        </div> */}
        <div className="container">
        <div className="olx_btn">
                <figure className="image">
                    <img src={OlxImg}/>
                </figure>
                <a href="https://hhauto.olx.ba/" target="_blank">{t('Pogledajte više na našem olx profilu.')}</a>
            </div>
        </div>
        
    </section>
);
    }
}

 const MyComponentCars = withTranslation()(Cars)
export default function Car() {
    return (
        <MyComponentCars />
    );
  }