import React from 'react';
import AboutImg from '../assets/images/about.jpg';
import {useTranslation} from 'gatsby-plugin-react-i18next';


const About = (props) => {
    const {t} = useTranslation();

    return(
    <section id="about" className="section about">
        <div className="container">
            <div className="columns is-multiline is-variable is-8">
                
                <div className="column is-6">
                    <div className="inner">
                        <header className="major">
                        <h3>{t('O nama')}</h3>

                        </header>
                            <p className="content">{t('HH AUTO je domaća kompanija koja se bavi uvozom i prodajom rabljenih vozila. Također nudimo mogućnost iznajmljivanja vozila u sklopu naše kompanije.')}</p>
                            <p className="content">{t('Prepoznatljivi smo na tržištu po kvaliteti, dobroj usluzi i sigurnoj kupovini.')}</p>
                            <p className="content">{t('HH AUTO je nastao 2015. godine u Travniku gdje se i nalazi naše sjedište. Nudimo preko 30 vozila različitih proizvođača, te imamo listu od preko 800 uspješno sklopljenih prodajnih ugovora.')}</p>
                            <a href="#icons" className="button special">{t('Više')}</a>

                    </div>
                </div>
                <div className="column is-6 with_image">
                    <figure className="image">
                        <img src={AboutImg}/>
                    </figure>
                </div>
                
            </div>
        </div>
    </section>
)
}

export default About
