import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <h2> Single Item</h2>
        <Slider {...settings}>
            <div>
        <div className="columns is-multiline is-mobile">
               
               <div className="column is-4">
                   <div className="card">
                       <div className="card-image">
                           <figure className="image is-4by3">
                               <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                           </figure>
                       </div>
                       <div className="card-content">
                           <p className="title is-4">Renault Megane</p>
                           <p className="price">18.900KM</p>
                           <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                       </div>
                   </div>
               </div>
               <div className="column is-4">
                   <div className="card">
                       <div className="card-image">
                           <figure className="image is-4by3">
                               <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                           </figure>
                       </div>
                       <div className="card-content">
                           <p className="title is-4">Renault Megane</p>
                           <p className="price">18.900KM</p>
                           <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                       </div>
                   </div>
               </div>
               <div className="column is-4">
                   <div className="card">
                       <div className="card-image">
                           <figure className="image is-4by3">
                               <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                           </figure>
                       </div>
                       <div className="card-content">
                           <p className="title is-4">Renault Megane</p>
                           <p className="price">18.900KM</p>
                           <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                       </div>
                   </div>
               </div>
               
           </div>
           </div>
           <div>
           <div className="columns is-multiline is-mobile">
               
                <div className="column is-4">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-4by3">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <p className="title is-4">2Renault Megane</p>
                            <p className="price">18.900KM</p>
                            <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-4by3">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <p className="title is-4">2Renault Megane</p>
                            <p className="price">18.900KM</p>
                            <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-4by3">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <p className="title is-4">2Renault Megane</p>
                            <p className="price">18.900KM</p>
                            <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                        </div>
                    </div>
                </div>
                
            </div>
            </div>
            <div>
            <div className="columns is-multiline is-mobile">
               
                <div className="column is-4">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-4by3">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <p className="title is-4">3Renault Megane</p>
                            <p className="price">18.900KM</p>
                            <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-4by3">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <p className="title is-4">3Renault Megane</p>
                            <p className="price">18.900KM</p>
                            <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-4by3">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <p className="title is-4">3Renault Megane</p>
                            <p className="price">18.900KM</p>
                            <a href="https://www.olx.ba/artikal/40862064/renault-megane-1-5-dci-2017/" rel="noreferrer"  className="button special" target="_blank">VIŠE</a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Slider>
      </div>
    );
  }
}